import { CheckoutSuccess } from "./CheckoutSuccess";
import { Hardware } from "./operator/Hardware";
import { Insights } from "./operator/Insights";
import { OperatorMenu } from "./operator/Menu";
import { OrderList } from "./operator/OrderList";
import { Screensaver } from "./screensaver/Screensaver";
import { SelfCheckout } from "./selfCheckout/SelfCheckout";
import { useAnyState } from "./state/StateProvider";
// Eslint complains about explicitly importing the html file
// eslint-disable-next-line import/no-unresolved
import flappy from "../../assets/flappy/flappy.html?url";

export const PageSwitcher = () => {
    const { state } = useAnyState();

    const getPage = () => {
        switch (state.kind) {
            case "welcome":
                return <Screensaver />;
            case "scan":
                return <SelfCheckout />;
            case "checkoutSuccess":
                return <CheckoutSuccess />;
            case "flappy":
                return <iframe className="h-full w-full" src={flappy}></iframe>;
            case "operator":
                switch (state.subState.kind) {
                    case "idle":
                        return <OperatorMenu />;
                    case "orders":
                        return <OrderList />;
                    case "insights":
                        return <Insights />;
                    case "hardware":
                        return <Hardware />;
                    default:
                        return state.subState satisfies never;
                }
            case "error":
                return <></>;
            default:
                return state satisfies never;
        }
    };

    return getPage();
};
