import { PropsWithChildren, createContext, useContext, Suspense } from "react";

import { Initializing } from "./Initializing";
import { ConfigClient } from "../clients/ConfigClient";
import { ProductClient } from "../clients/ProductClient";
import { Config } from "../types/Config";
import { httpClient } from "../utils/http";
import { wrapPromise } from "../utils/suspender";

const getConfiguration = async (): Promise<Config> => {
    const client = httpClient;
    const productClient = new ProductClient(client);
    const configClient = new ConfigClient(client);
    const config = await configClient.setup();

    const storePromoItems = await productClient.getStorePromotions();

    const promotionPromises = storePromoItems.map((promoItem) =>
        productClient
            .getPromotionalProduct(promoItem.barcode)
            .then((apiResult) => ({
                ...apiResult,
                name: promoItem.name,
                imageUrl: promoItem.imageUrl
            }))
    );
    const promotionalProducts = await Promise.all(promotionPromises);

    return {
        ...config,
        promotionalProducts
    };
};

const suspendableConfig = wrapPromise(getConfiguration());

const initialConfig: Config = {
    tenant: "kkiosk",
    storeId: "",
    selfCheckoutTerminalId: "",
    promotionalProducts: [],
    productPresets: [],
    checkinCode: ""
};

const ConfigContext = createContext<Config>(initialConfig);

const InternalProvider = ({ children }: PropsWithChildren) => {
    const config = suspendableConfig.read();
    return (
        <ConfigContext.Provider value={config}>
            <div data-theme={config.tenant} className="flex h-full flex-col">
                {children}
            </div>
        </ConfigContext.Provider>
    );
};

export const ConfigProvider = ({ children }: PropsWithChildren) => {
    return (
        <Suspense fallback={<Initializing />}>
            <InternalProvider>{children}</InternalProvider>
        </Suspense>
    );
};

export const useConfig = () => useContext(ConfigContext);
